export default {
  productionRequestUrl: "https://api.app.run4more.gr/v1",
  devRequestUrl: "https://api.app.run4more.gr/v1",
  tokenTypeBearer: true, // type of the Auth token is bearer
  API_KEY: "avd",
  ID: "id", //id or _id
  ID2: "_id", //id or _id
  licenceID: "string", // the id to take the activated modules,
  image_field_name: "image", // το ονομα που εχει το πεδιο με τις εικονες στο project
  maxImageSize: 2000000,
  logo: "",
  projectName: "Run4more",
};
